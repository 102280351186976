import {
  GlobeAltIcon,
  LightningBoltIcon,
  ScaleIcon,
} from "@heroicons/react/outline";
import React from "react";
import LongContent from "../assets/elements/LongContent";
import StandardHeader from "../assets/elements/StandardHeader";
import ThreeColumnFeatureSection from "../assets/elements/ThreeColumnFeatureSection";
import ThreeColumnUseCaseSection from "../assets/elements/ThreeColumnUseCaseSection";
import TwoFeatureOverview from "../assets/elements/TwoFeatureOverview";
import Layout from "../components/layout";
import Cta from "../partials/Cta";
import LeftColours from "../utilities/LeftColours";
import RightColours from "../utilities/RightColours";

import SvgBanIcon from "../assets/icons/ban_icon.svg";
import SvgInconsistentIcon from "../assets/icons/inconsistent_icon.svg";
import SvgInneficientIcon from "../assets/icons/inefficient_icon.svg";


import SvgEncordCodeSample from "../assets/images/feature-encord-code-sample.png";
import SEO from "../components/seo";

const useCasesImageHeight = 198;
const useCasesImageWidth = 352;

const useCases = [
  {
    name: "Non-scalable",
    description:
      "Current rates of data growth means that we will rapidly run out people to label data.",
    bullets: [],
    image: (
      <SvgBanIcon
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Ban icon"}
      />
    ),
  },
  {
    name: "Inconsistent",
    description:
      "Humans are error-prone and oftentimes disagree on what constitutes ground truth.",
    bullets: [],
    image: (
      <SvgInconsistentIcon
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Inconsistent icon"}
      />
    ),
  },
  {
    name: "Inefficient",
    description:
      "Shipping data back and forth between large annotation teams is slow and costly.",
    bullets: [],
    image: (
      <SvgInneficientIcon
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Inefficient icon"}
      />
    ),
  },
];

const featuresGrid = [
  {
    name: "Fast",
    description:
      "Let machines do the heavy lifting - scale models to millions of data points.",
    icon: GlobeAltIcon,
  },
  {
    name: "Cost-effective",
    description:
      "Realise economies of scale - automation gets cheaper with more labels.",
    icon: ScaleIcon,
  },
  {
    name: "Higher quality",
    description:
      "Pivot human supervision from creating to reviewing labels to ensure better results.",
    icon: LightningBoltIcon,
  },
];

const Automate = ({ location }) => {
  return (
    <Layout location={location}>
      {/*  Page illustration */}
      <LeftColours />
      <RightColours />

      <StandardHeader
        title={"AI-assisted labeling powered by micro-models"}
        description={
          "Rather than building large monolithic models, decompose your labeling task into much smaller parts and automate each one with micro-models."
        }
      />

      {/* Use cases */}
      <ThreeColumnUseCaseSection
        caseStudy={null}
        title={"Manual computer vision annotation"}
        header={"The problem with purely manual approaches"}
        description={
          <>
            Current methods of annotation require workforces of hundreds of
            thousands of people. This is fundamentally constrained, produces
            variable quality, and does not work for use cases where data privacy
            is important.
          </>
        }
        useCases={useCases}
      />

      {/* Three column feature section */}
      <ThreeColumnFeatureSection
        title={"Automated approach with micro-models"}
        header={"The benefits of automation"}
        description={
          "Encord's system removes human bottlenecks with novel automation techniques allowing you to quickly create large high-calibre training datasets."
        }
        features={featuresGrid}
      />

      <div className="flex justify-center max-w-full rounded-md mx-auto md:max-w-none h-auto">
        {/* <SvgMicroModelFlow
          width={1100}
          height={useCasesImageHeight}
          alt="Micro model flow"
        /> */}
        <img src="https://encord.cdn.prismic.io/encord/c22d3834-f954-40d1-81f1-2f1527038d0a_micro-model-flow-cropped.svg" loading="lazy" width={1100} height={198} alt="Micro model flow"  />
      </div>

      <br />
      <br />

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeHeader={true}
        includeGrayTransition={true}
        includeTopPadding={true}
        title={"How it works"}
        header={"A fresh perspective on computer vision"}
        description={
          "Rather than training large monolithic models, Encord breaks down annotation tasks into atomic units, allowing you to train, run, and ensemble targeted micro-models."
        }
        imageOne={null}
        imageTwo={null}
        imageWidthOne={800}
        imageWidthTwo={800}
        headerOne={null}
        headerTwo={null}
        descriptionOne={null}
        descriptionTwo={null}
      />

      <TwoFeatureOverview
        includeHeader={false}
        title={"Invest"}
        header={"Our investors 2"}
        description={"Our investors"}
        imageOne={
          <img
            src={
              "https://images.prismic.io/encord/c7c641c3-25af-400c-a3df-474d99388e20_download-models.png?auto=compress,format"
            }
            alt="Download models"
            loading="lazy"
            className="w-auto h-auto"
            width={0}
            height={0}
          />
        }
        imageTwo={
          <img
            src={SvgEncordCodeSample}
            alt="Download models"
            loading="lazy"
            className="w-auto h-auto"
            width={0}
            height={0}
          />
        }
        imageWidthOne={700}
        imageWidthTwo={500}
        headerOne={"Download & deploy"}
        headerTwo={"Active learning pipelines"}
        descriptionOne={
          "Download micro-models for integration with downstream applications with the click of a button. Deployable in secure environments and on edge."
        }
        descriptionTwo={
          "Integrate micro-models into existing data pipelines with our APIs and SDK. Combine your production model and micro-models with ease."
        }
      />

      <LongContent
        title={"Technology"}
        header={"Automating data annotation for computer vision"}
        description={
          "Use automation to save on human supervision and enhance quality."
        }
        content={
          <>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Encord has developed a wide range of automation features to
              annotate datasets to the highest quality standards to reduce the
              bottleneck of manual labour in the annotation process. These
              features include proprietary sampling, tracking, interpolation,
              auto-segmentation algorithms, and several intelligent heuristics.
              However, the core of our technology is a novel approach we call
              micro-models.
            </p>
            <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
              We believed there must be a better way to make AI practical from
              first starting the company. We have devised a unique and effective
              methodology for automating and streamlining the tasks related to
              preparing and managing quality training data.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              In contrast to traditional machine learning models that require
              large quantities of data and are fit for robustness and
              generalisability, our micro-models are tightly scoped and over-fit
              to narrow tasks and data distributions.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Our technology allows you to train micro-models in only a few
              minutes, starting with just a handful of labels, and then ensemble
              many micro-models together to cover your complete set of labeling
              tasks. Our platform allows you to assemble micro-models to cover
              arbitrarily complex annotation tasks.
            </p>
          </>
        }
      />

      <Cta
        location={location}
        pageName={"Automtate Page"}
        ctaText={"Book a demo today"}
      />
    </Layout>
  );
};

export default Automate;

export const Head = () => (
  <SEO
    title="Automate computer vision labeling with Encord"
    description="Automate computer vision annotation projects with AI-assisted labeling, interpolation, object tracking, and more."
  />
);
